.pricingContainer {
  width: 100%;
  padding: var(--marginPadding);
  display: flex;
  flex-direction: column;
  align-items: center;
  .selectSection {
    margin: var(--marginPadding) 0;
    button {
      width: 100px;
      border-radius: 3px;
      height: 35px;
      background: transparent;
      border: none;
      margin: 0 20px;
      outline: none;
      cursor: pointer;
      &.active {
        border: solid 1px black;
      }
    }
  }
  .priceSection {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
  }
}

.offerContainer {
  width: 100%;
  margin-top: -20px;
  background: black;
  padding: 0 var(--marginPadding) 20rem var(--marginPadding);
  span {
    color: white;
    font-size: 8rem;
    font-family: "MAZIUS REVIEW 20.09";
    font-weight: 100;
  }
  p{
    color: white;
  }
  img.plan{
    width: 100%;
    margin-top: 5rem;
  }
}

.offerSection{
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 8rem;
  margin-bottom: 20rem;
  padding-right: 100px;
}
