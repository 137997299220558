.designBox{
  cursor: pointer;
  width: 450px;
  height: auto;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin: 4rem;
  padding: 0.6rem;
  box-shadow: 1px 8px 32px -2px rgba(0, 0, 0, 0.1);
  img{
    width: 100%;
  }
  h2{
    font-size: 1.8rem;
    margin: 1.5rem 0 0.6rem 0;
  }
  button{
    margin: 0 0.6rem 0 0;
  }
  .colorList{
    display: flex;
    .color{
      margin: 1rem 0.6rem 0 0;
      width: 40px;
      height: 15px;
      border-radius: 2px;
    }
  }
}