.productModal {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
  &.active {
    pointer-events: auto;
    opacity: 1;
    cursor: initial;
  }
  .productWrapper {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    position: absolute;
    width: 75vw;
    height: 85vh;
    border-radius: 6px;
    background: var(--bgColor);
    padding: 50px;
    h1 {
      height: 10%;
      font-family: "Neue Montreal Regular";
    }
    .close {
      position: absolute;
      right: 50px;
      cursor: pointer;
      width: 25px;
    }
  }
  .productContent {
    width: 100%;
    height: 90%;
    display: flex;
    .visualSection {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;
      height: 100%;
      // background: red;
      img {
        position: relative;
        max-height: 90%;
        object-fit: cover;
        width: 100%;
      }
    }
    .infoSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 50px;
      width: 40%;
      height: 100%;
      h2 {
        font-size: 2.6rem;
      }
      // background: blue;
      .itemContent {
        margin: 1rem 0 2rem 0;
        display: flex;
        flex-wrap: wrap;
        .tag {
          padding: 0.25rem 1.5rem;
          border: solid 1px black;
          border-radius: 4px;
          margin-right: 1rem;
          margin-bottom: 1rem;
        }
        .color {
          width: 60px;
          height: 22px;
          border-radius: 2px;
          margin-right: 1rem;
          margin-bottom: 1rem;
        }
        a {
          padding: 15px 40px;
          background-color: black;
          border-radius: 4px;
          border: none;
          outline: none;
          color: white;
          margin-right: 1rem;
          margin-bottom: 1rem;
          cursor: pointer;
        }
      }
    }
  }
}
