/******************************
INTRO
*******************************/

.introContainer {
  .textContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: var(--marginPadding);
    h1{
      margin-bottom: 1.2rem;
    }
    p{
      margin: 1.8rem 0 0.5rem 0;
    }
    .buttonContainer{
      margin-top: 3.8rem;
    }
    .button{
      outline: none;
      border: none;
      color: white;
      background: var(--sandColor);
      padding: 1.2rem 4rem;
      margin-top: 3rem;
      border-radius: 4px;
      border: solid 1px var(--sandColor);
      font-size: 1.6rem;
      margin-right: 1.5rem;
      transition: background-color 0.6s;
      &:hover{
        background-color: var(--sandColorHover);
      }
      &.learn{
        background-color: white;
        color: var(--fontColor);
        border: solid 1px black;
        &:hover{
          background-color: black;
          color: white;
        }
      }
    }
  }
  .visualContainer {
    position: relative;
    width: 50%;
    height: 100%;
    img {
      z-index: 2;
      position: absolute;
      width: 100%;
      right: -8rem;
    }
  }
}

/******************************
PRESENTATION
*******************************/
.presentationContainer{
  margin-top: -20px;
  width: 100%;
  height: auto;
  background: black;;
}

.tripleTextSection{
  padding: 0 calc(var(--marginPadding) * 2.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20rem 0 25rem 0;
  p{
    width: 20%;
    color: white;
    margin-left: -8rem
  }
}

h1.bigTitle{
  font-size: 14rem;
  padding-bottom: 20rem;
  color: white;
  text-align: center;
  font-weight: 100;
}

p span{
  color: var(--sandColor);
}