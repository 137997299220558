/******************************
BASE
*******************************/

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  background: var(--bgColor);
  transition: background-color 0.5s;
}

/******************************
HEADER
*******************************/

header {
  z-index: 3;
  width: 100%;
  height: var(--headerHeight);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--marginPadding);
  a.logoContain {
    height: 100%;
    display: flex;
    align-items: center;
  }
  svg {
    left: var(--marginPadding);
    height: 50px;
    .svgFill {
      fill: var(--fontColor);
    }
  }
  span {
    font-size: 0.5rem;
  }
  .button {
    right: var(--marginPadding);
    border: none;
    outline: none;
    background: var(--sandColor);
    padding: 1rem 2rem;
    border-radius: 2px;
    border: solid 1px var(--sandColor);
    color: white;
    transition: background-color 0.5s;
    a {
      color: white;
    }
    cursor: pointer;
    &:hover{
      background-color: var(--sandColorHover);
    }
    &.learn {
      margin-right: 2rem;
      background: transparent;
      border: solid 1px var(--fontColor);
      color: var(--fontColor);
      &:hover{
        background-color: var(--fontColor);
        color: var(--bgColor);
      }
    }
  }
  nav a.navLink {
    position: relative;
    margin-right: 4rem;
    &::after {
      position: absolute;
      content: "";
      border-bottom: solid thin transparent;
      width: 0%;
      left: -5%;
      bottom: -6px;
      transition: width 1s;
    }
    &:hover,
    &.active {
      &::after {
        transition: width 1s;
        width: 110%;
        border-color: var(--fontColor);
      }
    }
  }
}

/******************************
CONTENT
*******************************/

section.content {
  max-width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  svg.wave {
    margin-top: -100px;
    width: 100%;
  }
}

/******************************
FOOTER
*******************************/

// FooterLink

.footerLinkContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--marginPadding);
  width: 100%;
  height: 155px;
  border-top: solid thin var(--fontColor);
  &.borderBottom {
    border-bottom: solid thin var(--fontColor);
  }
  img {
    width: 60px;
  }
  .a {
    font-size: 5.4rem;
  }
}

footer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .demiBox {
    position: relative;
    width: 50%;
    height: 300px;
    padding: var(--marginPadding);
    svg {
      left: var(--marginPadding);
      width: 300px;
      .svgFill {
        fill: var(--fontColor);
      }
    }
    &.left {
      border-right: solid thin var(--fontColor);
    }
    p.copyright {
      position: absolute;
      bottom: 2rem;
      right: 2rem;
      font-size: 1.2rem;
      color: var(--fontColor);
    }
  }
}
