.introContainer {
  padding: var(--marginPadding);
  width: 100%;
  height: var(--productContainerHeight);
  display: flex;
  align-items: center;
  .textContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 7rem;
    }
    p {
      margin-top: 10px;
      width: 70%;
      font-size: 1.8rem;
    }
  }
  img {
    width: 50%;
  }
}

.mainContainer {
  margin-top: 10rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .tagContainer {
    margin: 1rem 0 3rem 0;
    display: flex;
    align-items: center;
    span {
      font-size: 0.5rem;
    }
  }
  .designContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 0 10rem 0;
  }
}

button.tag {
  outline: none;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 8px 15px;
  border-radius: 2px;
  margin: 1.5rem;
  &.active {
    border: solid 1px black;
  }
}
