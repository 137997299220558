.loginSection {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // background: black;
  // color:white;
  form {
    margin-top: 5rem;
    width: 450px;
    // color: white;
    label {
      font-family: Neue Montreal Regular;
      font-size: var(--fontSize);
    }
    input {
      padding: 0.8rem 0rem;
      outline: none;
      margin-top: 0.5rem;
      margin-bottom: 2rem;
      width: 100%;
      background: transparent;
      color: var(--fontColor);
      border: none;
      border-bottom: solid 1px rgba(0, 0, 0, 0.3);
      font-size: 1.6rem;
      font-family: Neue Montreal Regular;
    }
  }
  .buttonContainer {
    width: 450px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    // margin-bottom: 15vh;
    margin-bottom: 15vh;
    a{
      color: gray;
      text-decoration: underline;
      margin-right: 1.5rem;
      font-size: 1.2rem;
    }
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.2rem 0rem;
    color: white;
    border: none;
    outline: none;
    background: var(--sandColor);
    border-radius: 3px;
    padding: 1rem 0;
    width: 48%;
    cursor: pointer;
    font-size: 1.4rem;
    &.github {
      width: 48%;
      background: black;
      color: white;
    }
    img {
      margin-right: 1.5rem;
      height: 2.8rem;
    }
  }
}

img.deco {
  position: absolute;
  right: 0;
}
