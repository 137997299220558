.presentationSection {
  padding: 0 calc(var(--marginPadding) * 2.5);
  z-index: 3;
  width: 100%;
  height: auto;
  display: flex;
  padding-bottom: 30rem;
  .textContainer {
    width: 50%;
    h1 {
      color: white;
      margin-bottom: 3rem;
    }
    p {
      color: white;
      width: 70%;
      margin-bottom: 3rem;
    }
    a {
      position: relative;
      display: flex;
      align-items: center;
      color: var(--sandColor);
      font-size: 1.8rem;
      &::after{
        position: absolute;
        content: "";
        border-bottom: solid thin var(--sandColor);
      }
      img{
        margin-top: 1.5px;
        margin-left: 6px;
        width: 15px;
      }
    }
    &.inverse{
      padding-left: 60px;
    }
  }
  .visualContainer {
    width: 50%;
    display: flex;
    justify-content: center;
    img {
      margin-top: 80px;
      width: 90%;
      object-fit: contain;
    }
  }
}
