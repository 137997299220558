/******************************
FONTS
*******************************/

@font-face {
  font-family: "Neue Montreal Bold";
  src: url("../assets/fonts/NeueMontreal-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Montreal Regular";
  src: url("../assets/fonts/NeueMontreal-Regular.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Montreal Light";
  src: url("../assets/fonts/NeueMontreal-Light.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MAZIUS REVIEW 20.09";
  src: url("../assets/fonts/MAZIUSREVIEW2009-Regular.woff2") format("woff2"),
    url("../assets/fonts/MAZIUSREVIEW2009-Regular.woff") format("woff");
  // font-weight: 100;
  // font-style: normal;
  // font-display: swap;
}

/******************************
BASE
*******************************/

:root {
  // colors
  --bgColor: #f7f4eb;
  --fontColor: black;
  --sandColor: #d8b286;
  --sandColorHover: #bd9466;
  // font size
  --titleSize: 5.5rem;
  --subtitleSize: 4rem;
  --headlineSize: 3rem;
  --linkSize: 1.4rem;
  --fontSize: 1.6rem;
  // padding
  --marginPadding: 8rem;
  // height
  --headerHeight: 150px;
  --productContainerHeight: 700px;
}

/******************************
TYPOGRAPHY
*******************************/
h1 {
  font-family: "MAZIUS REVIEW 20.09";
  font-size: var(--titleSize);
  color: var(--fontColor);
  font-weight: 100;
}

h2 {
  font-family: "Neue Montreal Regular";
  font-size: var(--subtitleSize);
  color: var(--fontColor);
  font-weight: 100;
}

h3 {
  font-family: "Neue Montreal Light";
  font-size: var(--headlineSize);
  color: var(--fontColor);
  font-weight: 100;
}

a {
  font-family: "Neue Montreal Regular";
  text-decoration: none;
  color: var(--fontColor);
  font-size: var(--linkSize);
}

p {
  font-family: "Neue Montreal Regular";
  font-size: var(--fontSize);
  line-height: 2.6rem;
  font-weight: 100;
  color: var(--fontColor);
}
